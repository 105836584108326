// material
import { Card } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

const CustomCard = styled(Card)(({ theme }) => ({
  padding: '24px',
  borderRadius: '10px',
  //  boxShadow: '0px 0px 10px 0px #0000000F' // neil ui
  //   boxShadow: '0px 2px 6px 2px #00000040' // goutham ui
  boxShadow:
    theme.palette.mode === 'light'
      ? 'rgba(145, 158, 171, 0.2) 0px 3px 5px -1px, rgba(145, 158, 171, 0.14) 0px 5px 8px 0px, rgba(145, 158, 171, 0.12) 0px 1px 14px 0px'
      : '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'
}));

// ----------------------------------------------------------------------
interface cardProps {
  sx?: Object;
  [x: string]: any;
}

export default function CustomCardComponent(props: cardProps) {
  const { children, sx = {} } = props;
  return <CustomCard sx={sx}>{children}</CustomCard>;
}
