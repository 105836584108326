import { useNavigate } from 'react-router';
import { Tooltip } from '@material-ui/core';
//
import { MAvatar } from './@material-extend';
import { MAvatarProps } from './@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ src, name, link, ...other }: MAvatarProps) {
  const navigate = useNavigate();

  const onHandleClick = () => {
    navigate(link);
  };

  return (
    <Tooltip onClick={onHandleClick} title={name} style={{ cursor: 'pointer' }}>
      <MAvatar
        link={link}
        name={name}
        src={src}
        alt={name}
        color={createAvatar(name).color}
        {...other}
        //
      >
        {createAvatar(name).name}
      </MAvatar>
    </Tooltip>
  );
}
