import { useState, ReactNode } from 'react';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, styled } from '@material-ui/core/styles';
import {
  Box,
  List,
  Divider,
  BoxProps,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  ListItemButton,
  ListItemButtonProps
} from '@material-ui/core';

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  color: theme.palette.text.primary
}));

interface ListItemStyleProps extends ListItemButtonProps {
  component?: ReactNode;
  to?: string;
}

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(({ theme }) => ({
  ...theme.typography.body2,
  height: 32,
  position: 'relative',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '16px',
  padding: theme.spacing(1),
  color: theme.palette.mode === 'light' ? '#374151' : '#ffff',
  borderRadius: 6,
  textTransform: 'capitalize'
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 16,
  height: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 8
});

// ----------------------------------------------------------------------

type NavItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  info?: JSX.Element;
  children?: {
    title: string;
    path: string;
  }[];
};

function NavItem({
  item,
  isShow,
  active
}: {
  item: NavItemProps;
  isShow?: boolean | undefined;
  active: any;
}) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen(!open);
  };

  const activeRootStyle = {
    color: 'primary.main',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
  };

  const activeSubStyle = {
    color: 'primary.main',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            // ...(isActiveRoot && activeRootStyle),
            height: '28px',
            padding: '6px 12px'
          }}
        >
          {/* <ListItemIconStyle>{icon && icon}</ListItemIconStyle> */}
          {isShow && (
            <>
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ width: 16, height: 16, mr: 1 }}
              />
              <ListItemText disableTypography primary={title} />
              {info && info}
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List
              component="div"
              sx={{
                padding: '0px 16px 0px 16px',
                display: 'flex',
                flexDirection: 'column',
                gap: '2px'
              }}
            >
              {children.map((item) => {
                const { title, path } = item;
                const isActiveSub = active(path);

                return (
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeSubStyle),
                      padding: '6px 8px',
                      borderRadius: '4px',
                      height: '28px',
                      fontSize: 13
                    }}
                  >
                    <ListItemIconStyle sx={{ marginRight: '5px' }}>
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: 'flex',
                          borderRadius: '50%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: 'text.disabled',
                          transition: (theme) => theme.transitions.create('transform'),
                          ...(isActiveSub && {
                            transform: 'scale(1)',
                            bgcolor: 'primary.main'
                          })
                        }}
                      />
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={title} />
                  </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

interface NavSectionProps extends BoxProps {
  isShow?: boolean | undefined;
  navConfig: {
    subheader: string;
    items: NavItemProps[];
    subIcon: any;
  }[];
}

export default function NavSection({ navConfig, isShow = true, ...other }: NavSectionProps) {
  const { pathname } = useLocation();
  const match = (path: any) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other} sx={{ padding: '88px 12px 0px 12px' }}>
      {navConfig.map((list, i) => {
        const { subheader, items, subIcon } = list;
        return (
          <List
            key={subheader}
            disablePadding
            style={{
              marginBottom: Number(i + 1) === navConfig.length ? '30px' : '0px',
              display: 'flex',
              flexDirection: 'column',
              gap: '4px'
            }}
          >
            {subheader && (
              <>
                <Divider sx={{ borderWidth: 1, borderColor: '#F0F2F6' }} />
                <ListItemStyle>
                  <ListItemIconStyle>{subIcon && subIcon}</ListItemIconStyle>
                  {isShow && <ListItemText disableTypography primary={subheader} />}
                </ListItemStyle>
              </>
            )}
            {/* {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>} */}
            {items.map((item: NavItemProps) => (
              <NavItem key={item.title} item={item} isShow={isShow} active={match} />
            ))}
          </List>
        );
      })}
    </Box>
  );
}
