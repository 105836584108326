import { createSlice } from '@reduxjs/toolkit';
// ----------------------------------------------------------------------

const initialState = {
  countryCodes: [],
  accessprofileAgentBlurField: [],
  allAgents: [],
  allAgentsTotalCount: [],
  minDefultaccessprofileFields: {},
  glcodeActive: [],
  auxDropdown: [],
  AlllobOfManager: [],
  raiseDisputeEdit: [],
  auxoflogedUser: []
};

const slice = createSlice({
  name: 'dropdownData',
  initialState,
  reducers: {
    countryCodesredux: (state, action) => {
      state.countryCodes = action.payload;
    },
    accessprofileAgentBlurFieldredux: (state, action) => {
      state.accessprofileAgentBlurField = action.payload;
    },
    minDefultaccessprofileFieldsredux: (state, action) => {
      state.minDefultaccessprofileFields = action.payload;
    },
    glcodeActiveredux: (state, action) => {
      state.glcodeActive = action.payload;
    },
    allAgentsredux: (state, action) => {
      state.allAgents = JSON.parse(JSON.stringify(action.payload));
    },
    allAgentsTotalCountredux: (state, action) => {
      state.allAgentsTotalCount = action.payload;
    },
    auxDropdownredux: (state, action) => {
      state.auxDropdown = action.payload;
    },
    AlllobOfManagerredux: (state, action) => {
      state.AlllobOfManager = action.payload;
    },
    raiseDisputeEditredux: (state, action) => {
      state.raiseDisputeEdit = action.payload;
    },
    auxoflogedUserredux: (state, action) => {
      state.auxoflogedUser = action.payload;
    },
    settoinitailState(state) {
      state.countryCodes = initialState.countryCodes;
      state.accessprofileAgentBlurField = initialState.accessprofileAgentBlurField;
      state.minDefultaccessprofileFields = initialState.minDefultaccessprofileFields;
      state.glcodeActive = initialState.glcodeActive;
      state.allAgents = initialState.allAgents;
      state.allAgentsTotalCount = initialState.allAgentsTotalCount;
      state.auxDropdown = initialState.auxDropdown;
      state.AlllobOfManager = initialState.AlllobOfManager;
      state.raiseDisputeEdit = initialState.raiseDisputeEdit;
      state.auxoflogedUser = initialState.auxoflogedUser;
    }
  }
});
export const {
  countryCodesredux,
  accessprofileAgentBlurFieldredux,
  minDefultaccessprofileFieldsredux,
  glcodeActiveredux,
  allAgentsredux,
  allAgentsTotalCountredux,
  auxDropdownredux,
  AlllobOfManagerredux,
  raiseDisputeEditredux,
  auxoflogedUserredux
} = slice.actions;
export default slice.reducer;
