import { createSlice } from '@reduxjs/toolkit';
// ----------------------------------------------------------------------

const initialState = {
  companyDetails: [],
  siteEnroll: [],
  clientEnroll: [],
  divisionEnroll: [],
  departmentEnroll: [],
  glcodeEnroll: [],
  lobEnroll: [],
  lobTotalCount: '0',
  teamsTotalCount: '0',
  auxEnroll: [],
  acessProfileEnroll: [],
  userRoleEnroll: [],
  holidayEnroll: [],
  revenueCycleEnroll: [],
  currencyEnroll: [],
  directMangerRequestEnroll: [],
  indirectMangerRequestEnroll: [],
  teamsEnroll: []
};

const slice = createSlice({
  name: 'masterData',
  initialState,
  reducers: {
    companyDetailsredux: (state, action) => {
      state.companyDetails = action.payload;
    },
    siteEnrollredux: (state, action) => {
      state.siteEnroll = action.payload;
    },
    clientEnrollredux: (state, action) => {
      state.clientEnroll = action.payload;
    },
    divisionEnrollredux: (state, action) => {
      state.divisionEnroll = action.payload;
    },
    departmentEnrollredux: (state, action) => {
      state.departmentEnroll = action.payload;
    },
    lobEnrollredux: (state, action) => {
      state.lobEnroll = action.payload;
    },
    lobTotalCountredux: (state, action) => {
      state.lobTotalCount = action.payload;
    },
    teamsTotalCountredux: (state, action) => {
      state.teamsTotalCount = action.payload;
    },
    glcodeEnrollredux: (state, action) => {
      state.glcodeEnroll = action.payload;
    },
    auxEnrollredux: (state, action) => {
      state.auxEnroll = action.payload;
    },
    acessProfileEnrollredux: (state, action) => {
      state.acessProfileEnroll = action.payload;
    },
    userRoleEnrollredux: (state, action) => {
      state.userRoleEnroll = action.payload;
    },
    holidayEnrollredux: (state, action) => {
      state.holidayEnroll = action.payload;
    },
    revenueCycleEnrollredux: (state, action) => {
      state.revenueCycleEnroll = action.payload;
    },
    directMangerRequestEnrollredux: (state, action) => {
      state.directMangerRequestEnroll = JSON.parse(JSON.stringify(action.payload));
    },
    indirectMangerRequestEnrollredux: (state, action) => {
      state.indirectMangerRequestEnroll = JSON.parse(JSON.stringify(action.payload));
    },
    teamsEnrollredux: (state, action) => {
      state.teamsEnroll = action.payload;
    },
    currencyEnrollredux: (state, action) => {
      state.currencyEnroll = action.payload;
    },
    settoinitailState(state) {
      state.companyDetails = initialState.companyDetails;
      state.siteEnroll = initialState.siteEnroll;
      state.divisionEnroll = initialState.divisionEnroll;
      state.departmentEnroll = initialState.departmentEnroll;
      state.glcodeEnroll = initialState.glcodeEnroll;
      state.lobEnroll = initialState.lobEnroll;
      state.lobTotalCount = initialState.lobTotalCount;
      state.teamsTotalCount = initialState.teamsTotalCount;
      state.auxEnroll = initialState.auxEnroll;
      state.acessProfileEnroll = initialState.auxEnroll;
      state.userRoleEnroll = initialState.auxEnroll;
      state.holidayEnroll = initialState.holidayEnroll;
      state.revenueCycleEnroll = initialState.revenueCycleEnroll;
      state.directMangerRequestEnroll = initialState.directMangerRequestEnroll;
      state.indirectMangerRequestEnroll = initialState.indirectMangerRequestEnroll;
      state.currencyEnroll = initialState.currencyEnroll;
      state.teamsEnroll = initialState.teamsEnroll;
    }
  }
});
export const {
  companyDetailsredux,
  siteEnrollredux,
  clientEnrollredux,
  divisionEnrollredux,
  departmentEnrollredux,
  lobEnrollredux,
  glcodeEnrollredux,
  auxEnrollredux,
  acessProfileEnrollredux,
  userRoleEnrollredux,
  holidayEnrollredux,
  revenueCycleEnrollredux,
  lobTotalCountredux,
  teamsTotalCountredux,
  directMangerRequestEnrollredux,
  indirectMangerRequestEnrollredux,
  currencyEnrollredux,
  teamsEnrollredux
} = slice.actions;
export default slice.reducer;
