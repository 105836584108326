// import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
// hooks
import AnnouncementBar from 'components/run-announcement/AnnouncementBar';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useAuth from '../../hooks/useAuth';
// components
import { MHidden } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
// import useCheckPermissions from '../../components/common/checkPermission';

// import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';
// import ContactsPopover from './ContactsPopover';
// import NotificationsPopover from './NotificationsPopover';
// import SettingsPopover from './SettingsPopover';
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 200;
const COLLAPSE_WIDTH = 30;

const APPBAR_MOBILE = 52;
const APPBAR_DESKTOP = 52;

const RootStyle = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow:
    // ? 'rgba(145, 158, 171, 0.2) 0px 3px 5px -1px, rgba(145, 158, 171, 0.14) 0px 5px 8px 0px, rgba(145, 158, 171, 0.12) 0px 1px 14px 0px'
    theme.palette.mode === 'light'
      ? '0px 0px 6px 2px #00000040'
      : '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
  // borderBottom: `1px solid ${theme.palette.divider}`
}));

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
};

export default function DashboardNavbar({ onOpenSidebar }: DashboardNavbarProps) {
  const { isCollapse } = useCollapseDrawer();
  const { user, logout } = useAuth();

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
        })
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />
        <AnnouncementBar />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover /> */}
          {/* <ContactsPopover /> */}
          {/* <SettingsPopover /> */}
          {/* {navBarmenus.length > 0 && <SettingsPopover />} */}
          {/* <NotificationsPopover /> */}
          <MyAvatar
            link={PATH_DASHBOARD.user.profile}
            src=""
            name={user?.first_name}
            sx={{ width: '34px', height: '34px', fontSize: '1rem' }}
          />
          <Box>
            <Typography variant="body2" sx={{ fontSize: '14px', color: 'text.primary' }}>
              {user?.first_name} {user?.last_name}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '12px', color: 'text.secondary' }}>
              {user?.email}
            </Typography>
          </Box>
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
