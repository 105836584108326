import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import Marquee from 'react-fast-marquee';
import { getannouncement } from '../../services/announcementServices';

//-------------------------------------------------------------------

const AnnouncementBar = () => {
  //   const [allAnnouncement, setAllAnnouncement] = useState([]);
  const [allAnnouncementBar, setAllAnnouncementBar] = useState<any>([]);

  useEffect(() => {
    getannouncement().then((result) => {
      //   setAllAnnouncement(result.data);
      const a: any = result?.data?.filter((row: any, i: any) => {
        const startDate = new Date(`${row.start_date} 00:00:00`);
        const endDate = new Date(`${row.end_date} 23:59:59`);
        const today = new Date();
        if (startDate <= today && today <= endDate && row.status !== 'ST') {
          return true;
        }
        return null;
      });
      setAllAnnouncementBar(a);
    });
  }, []);

  return (
    <Marquee
      style={{ color: 'black' }}
      pauseOnHover={true}
      speed={30}
      delay={2}
      // gradient={true}
      // gradientColor={[255, 0, 10]}
      // gradientWidth={200}
    >
      {allAnnouncementBar.map((row: any, i: any) => (
        <Box sx={{ typography: 'h6', ml: 8 }} key={i}>
          *{row.announcement}*
        </Box>
      ))}
    </Marquee>
  );
};

export default AnnouncementBar;
