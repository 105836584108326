import { createSlice } from '@reduxjs/toolkit';
import { dispatch, store } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  clock_in_out: '',
  clockintime: '',
  clockouttime: '',
  timer: 0,
  defaultAuxR: 0,
  selectedR: 0,
  prevselectedR: 0,
  idR: '',
  idRSession: '',
  attendanceDate: ''
};

const slice = createSlice({
  name: 'attendance',
  initialState,
  reducers: {
    clockintimeredux: (state, action) => {
      state.clockintime = action.payload;
    },
    clockouttimeredux: (state, action) => {
      state.clockouttime = action.payload;
    },
    timerRedux: (state) => {
      state.timer += 1;
    },
    timerReduxChange: (state, action) => {
      state.timer = action.payload;
    },
    timerReset: (state) => {
      state.timer = 0;
    },
    attendance_clockin(state) {
      state.clock_in_out = 'clock-in';
    },
    attendance_clockout(state) {
      state.clock_in_out = 'clock-out';
    },
    attendance_clock(state) {
      state.clock_in_out = '';
    },
    defaultAuxRT(state, action) {
      state.defaultAuxR = action.payload;
    },
    selectedRT(state, action) {
      state.selectedR = action.payload;
    },
    prevselectedRT(state, action) {
      state.prevselectedR = action.payload;
    },
    idRT(state, action) {
      state.idR = action.payload;
    },
    attendanceDateT(state, action) {
      state.attendanceDate = action.payload;
    },
    idRSessionT(state, action) {
      state.idRSession = action.payload;
    },
    settoinitailState(state) {
      state.clock_in_out = initialState.clock_in_out;
      state.clockintime = initialState.clockintime;
      state.clockouttime = initialState.clockouttime;
      state.timer = initialState.timer;
      state.selectedR = initialState.selectedR;
      state.prevselectedR = initialState.prevselectedR;
      state.idR = initialState.idR;
      state.attendanceDate = initialState.attendanceDate;
      state.idRSession = initialState.idRSession;
    }
  }
});
export const {
  attendance_clockin,
  attendance_clockout,
  attendance_clock,
  clockintimeredux,
  timerRedux,
  timerReset,
  selectedRT,
  prevselectedRT,
  defaultAuxRT,
  idRT,
  attendanceDateT,
  idRSessionT,
  clockouttimeredux,
  timerReduxChange,
  settoinitailState
} = slice.actions;
export default slice.reducer;
