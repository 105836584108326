import { createSlice } from '@reduxjs/toolkit';
import { dispatch, store } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  pageTitle: 'Attendance'
};

const slice = createSlice({
  name: 'attendance',
  initialState,
  reducers: {
    pageTitleRedux: (state, action) => {
      state.pageTitle = action.payload;
    }
  }
});
export const { pageTitleRedux } = slice.actions;
export default slice.reducer;
