// material
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
// components
import { CustomCardComponent } from 'components/common/customCard';
import { Card } from '@material-ui/core';
import Page from '../../components/Page';
import { LoginForm } from '../../components/authentication/login';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  },
  // background:
  //   theme.palette.mode === 'light'
  //     ? 'linear-gradient(9deg, #2672F4 0%, rgb(255, 255, 255) 30%, #1C64F2 70%)'
  //     : 'none'
  backgroundImage: "url('./dashboardicon/newloginBG.png')",
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
  backgroundPosition: 'center',
  height: '100vh'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  padding: 10
}));

// const BoxStyle = styled(Box)(({ theme }) => ({
//   paddingBottom: 30,
//   // display: 'flex',
//   // alignItems: 'center',
//   color: theme.palette.primary.main
// }));

// ----------------------------------------------------------------------

const style = {
  width: '180px',
  height: '80px',
  borderRadius: '50% 50% 0 0 / 100% 100% 0 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  mr: '300px'
};
export default function Login() {
  return (
    <RootStyle title="Login | payrolle 360">
      {/* <Grid container spacing={3}> */}
      {/* <Container maxWidth="lg"> */}
      <Card sx={{ ...style }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: '40px' }}>
          <img
            src="static/icons/navbar/PayGologo.svg"
            alt="Logo"
            width="140px"
            height="50px"
            //
          />
        </Box>
      </Card>
      <CustomCardComponent sx={{ boxShadow: 'none', minWidth: '550px' }}>
        {/* <BoxStyle>
          <Box width={600}>
            <Typography variant="h4">Welcome to PayGo</Typography>
            <Typography variant="subtitle2">Trusted by hundreds of customer-obsessed teams</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src="/static/icons/navbar/PayGologo.svg"
              alt="Logo"
              width="120px"
              height="60px"
              //
            />
          </Box>
        </BoxStyle> */}
        <ContentStyle>
          {/* <Grid item xs={12} md={6}>
            <Grid container display="flex" flexDirection="column">
              <BoxStyle>
                <Typography variant="h4">Welcome to PayGo</Typography>
                <Typography variant="subtitle2">
                  Trusted by hundreds of customer-obsessed teams
                </Typography>
              </BoxStyle>
              <Box>
                <img
                  src="/static/loginaimg.png"
                  alt="bgimageloginscreen"
                  width="350px"
                  height="300px"
                />
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ borderWidth: 1, borderColor: '#F0F2F6' }} /> */}
          {/* <Grid item md={12} xs={12}> */}
          <Grid container justifyContent="center">
            <Grid item md={12} xs={6} display="flex" flexDirection="column" gap={0}>
              {/* <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src="static/icons/navbar/PayGologo.svg"
                  alt="Logo"
                  width="140px"
                  height="60px"
                  //
                />
              </Box> */}
              {/* <Typography variant="subtitle1">Sign in with your email and password.</Typography> */}
              <LoginForm />
            </Grid>
          </Grid>
          {/* </Grid> */}
        </ContentStyle>
      </CustomCardComponent>
      {/* </Container> */}
      {/* </Grid> */}
    </RootStyle>
  );
}
