import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import attendance from './slices/attendance';
import page from './slices/pageslice';
import dropdownData from './slices/dropdownDatas';
import masterData from './slices/masterDatas';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};
const attendancePersistConfig = {
  key: 'attendance',
  storage,
  keyPrefix: 'redux-',
  whitelist: [
    'clockintime',
    'clockouttime',
    'clock_in_out',
    'timer',
    'selectedR',
    'defaultAuxR',
    'prevselectedR',
    'idR',
    'attendanceDate'
  ]
};
const pageConfig = {
  key: 'page',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['pageTitle']
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  dropdownDatas: dropdownData,
  masterDatas: masterData,
  attendance: persistReducer(attendancePersistConfig, attendance),
  page: persistReducer(pageConfig, page),
  product: persistReducer(productPersistConfig, productReducer)
});

export { rootPersistConfig, rootReducer };
