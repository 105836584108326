// material
import { Box, BoxProps } from '@material-ui/core';
// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  return (
    <Box sx={{ ...sx }}>
      <img src="/static/logo/logoframe.png" alt="logo" />
    </Box>
  );
}
