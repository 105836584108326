export const baseUrl = 'https://payroll360-api.cp360apps.com/';
export const imgbaseUrl = 'https://payroll360-api.cp360apps.com';
export const websocketUrl = 'wss://websocket.getpaygo.com/ws/punch-in/';
// export const baseUrl = 'http://payroll360develop-api.cp360apps.com';
// export const imgbaseUrl = 'http://payroll360develop-api.cp360apps.com';
// export const websocketUrl = 'ws://payroll360develop-api.cp360apps.com/ws/punch-in/';

// dev 3 envirment
// export const baseUrl = 'http://35.182.23.254';
// export const imgbaseUrl = 'http://35.182.23.254';
// export const websocketUrl = 'ws://35.182.23.254/ws/punch-in/';

export const minutesArray = Array.from(Array(60).keys());

export const minutesArray0 = Array.from(Array(1).keys());

export const hoursArray = Array.from(Array(24).keys());

export const hoursArray12 = Array.from(Array(13).keys());

export const dropOptionsMap = [
  { id: 'site', value: 'Sites' },
  { id: 'division', value: 'Division' },
  { id: 'department', value: 'Department' },
  { id: 'lob', value: 'LOB' },
  { id: 'team', value: 'TEAM' }
];
export const dropOptionsMap2 = [
  { id: 'site', value: 'Sites' },
  { id: 'division', value: 'Division' },
  { id: 'department', value: 'Department' },
  { id: 'lob', value: 'LOB' },
  { id: 'team', value: 'TEAM' },
  { id: '', value: 'Clear' }
];

export const dropOptionsMapLobOnly = [{ id: 'lob', value: 'LOB' }];
export const dropOptionsMapTeamOnly = [{ id: 'team', value: 'TEAM' }];

export const paginationPerpage = 10;
export const rowperpageOption = [10, 20, 30, 50];

export const Gender = [
  {
    id: 'M',
    name: 'Male'
  },
  {
    id: 'F',
    name: 'Female'
  },
  {
    id: 'O',
    name: 'Other'
  }
];

export const SelfFilter = [
  {
    id: 'self',
    name: 'Include Self'
  },
  {
    id: 'hide',
    name: 'Hide Self'
  },
  {
    id: 'onlyself',
    name: 'Only Self'
  }
];
export const PeriodFilter = [
  {
    id: '0',
    name: 'Clear'
  },
  {
    id: '1',
    name: '1 Month'
  },
  {
    id: '3',
    name: 'Three Month'
  },
  {
    id: '6',
    name: 'Six Month'
  }
];

export const formatsArrayCSV = ['.csv', '.xlsx', '.xls'];

export const AgenTNonfilter = [
  {
    id: 'agent',
    name: 'Agent'
  },
  {
    id: 'NonAgent',
    name: 'Non Agent'
  }
];
export const AgenTNonBothfilter = [
  {
    id: 'both',
    name: 'Both'
  },
  {
    id: 'agent',
    name: 'Agent'
  },
  {
    id: 'NonAgent',
    name: 'Non Agent'
  }
];
export const ActiveInActiveBothfilter = [
  {
    id: 'active',
    name: 'Active'
  },
  {
    id: 'inactive',
    name: 'InActive'
  },
  {
    id: 'both',
    name: 'Both'
  }
];

export const budgetingFilter = [
  {
    id: 'EmployeeCount',
    name: 'Employee Count'
  },
  {
    id: 'PayableHours',
    name: 'Payable Hours'
  }
];
export const checkKeys = ['divisions', 'department', 'lob', 'site', 'client', 'glcode'];

// export const formatsArrayCSV = ['csv', 'xlsx', 'xls'];

export const BudgetedTableSkeltonPayHrs = [
  {
    name: 'Employee Count',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: '',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Normal Budgeted Rate',
    field1: 'number',
    field2: 'number',
    field3: '$',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Holiday Budgeted Rate',
    field1: 'number',
    field2: 'number',
    field3: '$',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Normal Working Days',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: '',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Holiday Working Days',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: '',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Workhours per Day',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: '',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Budgeted Normal Billable Hrs',
    field1: 'number',
    field2: 'number',
    field3: 'hrs',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Budgeted Holiday Billable Hrs',
    field1: 'number',
    field2: 'number',
    field3: 'hrs',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Budgeted Normal Revenue',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: '$',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Budgeted Holiday Revenue',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: '$',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Positive Adjustment',
    field1: 'number',
    field2: 'number',
    field3: '$',
    totalAgent: '',
    totalNonAgent: '',
    total: 'Positive Adjustment Note',
    totalValue: '',
    totalField: 'input'
  },
  {
    name: 'Negative Adjustment',
    field1: 'number',
    field2: 'number',
    field3: '$',
    totalAgent: '',
    totalNonAgent: '',
    total: 'Negative Adjustment Note',
    totalValue: '',
    totalField: 'input'
  },
  {
    name: 'Budgeted Revenue',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: '$',
    totalAgent: '',
    totalNonAgent: '',
    total: 'Total Budgeted Revenue',
    totalValue: '',
    totalField: ''
  }
];

export const BudgetedTableSkeltonEmpCount = [
  {
    name: 'Employee Count',
    field1: 'number',
    field2: 'number',
    field3: '',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Normal Budgeted Rate',
    field1: 'number',
    field2: 'number',
    field3: '$',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Holiday Budgeted Rate',
    field1: 'number',
    field2: 'number',
    field3: '$',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Normal Working Days',
    field1: 'number',
    field2: 'number',
    field3: 'Days',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Holiday Working Days',
    field1: 'number',
    field2: 'number',
    field3: 'Days',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Workhours per Day',
    field1: 'number',
    field2: 'number',
    field3: 'Hrs',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Budgeted Normal Billable Hrs',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: 'Hrs',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Budgeted Holiday Billable Hrs',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: 'Hrs',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Budgeted Normal Revenue',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: '$',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Budgeted Holiday Revenue',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: '$',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Positive Adjustment',
    field1: 'number',
    field2: 'number',
    field3: '$',
    totalAgent: '',
    totalNonAgent: '',
    total: 'Positive Adjustment Note',
    totalValue: '',
    totalField: 'input'
  },
  {
    name: 'Negative Adjustment',
    field1: 'number',
    field2: 'number',
    field3: '$',
    totalAgent: '',
    totalNonAgent: '',
    total: 'Negative Adjustment Note',
    totalValue: '',
    totalField: 'input'
  },
  {
    name: 'Budgeted Revenue',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: '$',
    totalAgent: '',
    totalNonAgent: '',
    total: 'Total Budgeted Revenue',
    totalValue: '',
    totalField: ''
  }
];

export const BudgetedActualTableSkelton = [
  {
    name: 'Actual Normal Working Days',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: 'days',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Actual Holiday Working Days',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: 'days',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Actual Total Billable Hours',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: 'Hrs',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Normal Days Act Billable Hours',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: 'Hrs',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Overage on Normal',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: 'Hrs',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Normal Billable hrs After Overage',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: 'Hrs',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Holiday days Act Billable Hours',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: 'Hrs',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Actual Normal Revenue',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: '$',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Actual Holiday Revenue',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: '$',
    totalAgent: '',
    totalNonAgent: '',
    totalField: ''
  },
  {
    name: 'Positive Adjustment',
    field1: 'number',
    field2: 'number',
    field3: '$',
    totalAgent: '',
    totalNonAgent: '',
    total: 'Positive Adjustment Note',
    totalValue: '',
    totalField: 'input'
  },
  {
    name: 'Negative Adjustment',
    field1: 'number',
    field2: 'number',
    field3: '$',
    totalAgent: '',
    totalNonAgent: '',
    total: 'Negative Adjustment Note',
    totalValue: '',
    totalField: 'input'
  },
  {
    name: 'Actual Revenue',
    field1: 'disbaled',
    field2: 'disbaled',
    field3: '$',
    totalAgent: '',
    totalNonAgent: '',
    total: 'Total Actual Revenue',
    totalValue: '',
    totalField: 'text'
  }
];

export const blockInvalidChar = (e: any) =>
  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

export function formatDateYYMMDDAY(dateString: any) {
  const dateObject = new Date(dateString);
  const year = dateObject.getUTCFullYear();
  const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(dateObject);
  const day = dateObject.getUTCDate();
  const day2 = new Date(dateString).getUTCDay();
  const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const weekday = weekdays[day2];
  const formattedDate = `${year} ${month} ${day} (${weekday})`;
  return formattedDate;
}
