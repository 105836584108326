import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
// import { alpha, styled } from '@material-ui/core/styles';
import { styled } from '@material-ui/core/styles';
import ArrowBackIosNewIcon from '@material-ui/icons/ArrowBackIos';
import Box from '@material-ui/core/Box';
import Stack from '@material-ui/core/Stack';
import Drawer from '@material-ui/core/Drawer';
import Tooltip from '@material-ui/core/Tooltip';
// import CardActionArea from '@material-ui/core/CardActionArea';
// hooks
// import useAuth from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// routes
// import { PATH_DASHBOARD, PATH_DOCS } from '../../routes/paths';
// components
// import Logo from '../../components/Logo';
// import MyAvatar from '../../components/MyAvatar';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
// import sidebarConfig from './SidebarConfig';
// import { DocIllustration } from '../../assets';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 230;
const COLLAPSE_WIDTH = 52;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

// const AccountStyle = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(2, 2.5),
//   borderRadius: theme.shape.borderRadiusSm,
//   backgroundColor: theme.palette.grey[500_12]
// }));

// ----------------------------------------------------------------------

const ToggleOpenIcon = styled(ArrowBackIosNewIcon)(({ theme }) => ({
  position: 'absolute',
  right: 10,
  width: 20,
  height: 20,
  color: theme.palette.primary.main,
  cursor: 'pointer'
}));

type IconCollapseProps = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};

function IconCollapse({ onToggleCollapse, collapseClick }: IconCollapseProps) {
  return (
    <Tooltip title="Collapse Menu">
      {/* <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          border: 'solid 1px currentColor',
          padding: '5px',
          marginLeft: '10px',
          ...(collapseClick && {
            borderWidth: 2
          })
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            bgcolor: 'currentColor',
            transition: (theme) => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0
            })
          }}
        />
      </CardActionArea> */}
      <ToggleOpenIcon
        sx={{ transform: collapseClick ? '' : 'rotate(180deg)', transition: 'all 0.3s' }}
        onClick={onToggleCollapse}
      />
    </Tooltip>
  );
}

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
  navBar: any;
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  navBar
}: DashboardSidebarProps) {
  const { pathname } = useLocation();
  // const { user } = useAuth();
  const { isCollapse, collapseClick, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
          ...(isCollapse && {
            alignItems: 'center'
          })
        }
      }}
    >
      <Stack
        sx={{
          ...(isCollapse && {
            alignItems: 'center'
          }),
          width: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
          position: 'fixed',
          zIndex: 999,
          borderTopRightRadius: '2rem',
          background: (theme) => (theme.palette.mode === 'light' ? '#fff' : '#161C24')
        }}
      >
        <Stack sx={{ pt: 3, pb: 3 }} direction="row" alignItems="center" justifyContent="center">
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            {/* <Logo /> */}
            {isCollapse ? (
              <img alt="Logo" src="/static/icons/navbar/Group 176.svg" width="33px" />
            ) : (
              <img
                alt="Logo"
                src="/static/icons/navbar/PayGologo.svg"
                width="120px"
                height="40px"
              />
            )}
          </Box>

          <MHidden width="lgDown">
            {!isCollapse && (
              <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
            )}
          </MHidden>
        </Stack>

        {/* {isCollapse ? (
          <MyAvatar sx={{ mx: 'auto', mb: 2 }} />
        ) : (
          <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.user.profile}>
            <AccountStyle>
              <MyAvatar />
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                  {user?.displayName}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {user?.role}
                </Typography>
              </Box>
            </AccountStyle>
          </Link>
        )} */}
      </Stack>

      <NavSection navConfig={navBar} isShow={!isCollapse} />

      <Box sx={{ flexGrow: 1 }} />

      {/* {!isCollapse && (
        <Stack
          spacing={3}
          alignItems="center"
          sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center' }}
        >
          <DocIllustration sx={{ width: 1 }} />

          <div>
            <Typography gutterBottom variant="subtitle1">
              Hi, {user?.displayName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Need help?
              <br /> Please check our docs
            </Typography>
          </div>
          <Button href={PATH_DOCS} target="_blank" variant="contained">
            Documentation
          </Button>
        </Stack>
      )} */}
    </Scrollbar>
  );
  if (navBar.length > 0) {
    return (
      <RootStyle
        sx={{
          width: {
            lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
          },
          height: '100%',
          ...(collapseClick && {
            position: 'absolute'
          })
        }}
      >
        <MHidden width="lgUp">
          <Drawer
            open={isOpenSidebar}
            onClose={onCloseSidebar}
            PaperProps={{
              sx: { width: DRAWER_WIDTH, height: '100%' }
            }}
          >
            {renderContent}
          </Drawer>
        </MHidden>

        <MHidden width="lgDown">
          <Drawer
            open
            variant="persistent"
            onMouseEnter={onHoverEnter}
            onMouseLeave={onHoverLeave}
            PaperProps={{
              sx: {
                width: DRAWER_WIDTH,
                bgcolor: 'background.default',
                ...(isCollapse && {
                  width: COLLAPSE_WIDTH
                }),
                borderTopRightRadius: '2rem',
                borderBottomRightRadius: '2rem',
                borderRight: 0,
                // ...(collapseHover && {
                //   borderRight: 0,
                //   backdropFilter: 'blur(6px)',
                //   WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                //   boxShadow: (theme) => theme.customShadows.z20,
                //   bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
                // })
                // ? 'rgba(145, 158, 171, 0.2) 0px 3px 5px -1px, rgba(145, 158, 171, 0.14) 0px 5px 8px 0px, rgba(145, 158, 171, 0.12) 0px 1px 14px 0px'
                boxShadow: (theme) =>
                  theme.palette.mode === 'light'
                    ? '0px -2px 6px 2px #00000040'
                    : '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'
              }
            }}
          >
            {renderContent}
          </Drawer>
        </MHidden>
      </RootStyle>
    );
  }
  return null;
}
