import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack5';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import lockOutline from '@iconify/icons-eva/lock-outline';
import closeOutline from '@iconify/icons-eva/close-outline';
import { useTheme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Stack from '@material-ui/core/Stack';
import Alert from '@material-ui/core/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import LoadingButton from '@material-ui/lab/LoadingButton';
import InputLabel from '@material-ui/core/InputLabel';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import MIconButton from '../../@material-extend/MIconButton';

// ----------------------------------------------------------------------
type InitialValues = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};
export default function LoginForm() {
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await login(values.email, values.password);
        enqueueSnackbar('Login success', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error: any) {
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.detail });
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = async () => {
    setShowPassword((show) => !show);
  };
  const resetusername = () => {
    formik.setFieldValue('email', '');
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
          <InputLabel focused htmlFor="usrnameEmailid">
            Username
          </InputLabel>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            size="small"
            // label="Please enter your username"
            variant="outlined"
            id="usrnameEmailid"
            {...getFieldProps('email')}
            sx={{
              '& .MuiOutlinedInput-input': {
                paddingLeft: 2,
                height: '25px'
              }
            }}
            InputProps={{
              style: {
                borderRadius: '6px'
              },
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start">
                    <Icon icon="mdi:user" />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={resetusername} edge="end">
                    <Icon icon={closeOutline} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <InputLabel focused htmlFor="paswwrdid">
            Password
          </InputLabel>
          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            // label="Please enter your Password"
            size="small"
            id="paswwrdid"
            {...getFieldProps('password')}
            sx={{
              '& .MuiOutlinedInput-input': {
                paddingLeft: 2,
                height: '25px'
              }
            }}
            InputProps={{
              style: {
                borderRadius: '6px'
              },
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start">
                    <Icon icon="eva:lock-fill" />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
            Forgot password?
          </Link>
        </Stack>

        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={12}>
            <LoadingButton
              fullWidth
              // size="medium"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{
                color: '#ffff',
                background:
                  // theme.palette.mode === 'light'
                  'linear-gradient(to right, theme.palette.primary.lighter, theme.palette.primary.main)'
                // : 'none'
              }}
            >
              Login
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
