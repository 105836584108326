import axios from 'axios';

const getannouncement = async (progressCallback?: (progress: number) => void) => {
  if (progressCallback) {
    const response = axios.get('/announcement/announcement/announcement/', {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const progress = Math.round((loaded * 100) / total);
        progressCallback(progress);
      }
    });
    return response;
  }
  const response = await axios.get('/announcement/announcement/announcement/');
  return response;
};
const postannouncement = async (
  start_date: any,
  end_date: any,
  announcement_title: any,
  announcement: any,
  added_by: any
) => {
  const response = await axios.post('/announcement/announcement/announcement/', {
    start_date,
    end_date,
    announcement_title,
    announcement,
    added_by
  });
  return response;
};
const patchannouncement = async (
  id: any,
  start_date: any,
  end_date: any,
  announcement_title: any,
  announcement: any,
  updated_by: any
) => {
  const response = await axios.patch('/announcement/announcement/announcement/', {
    id,
    start_date,
    end_date,
    announcement_title,
    announcement,
    updated_by
  });
  return response;
};
const patchannouncementForStop = async (id: any, status: any, updated_by: any) => {
  const response = await axios.patch('/announcement/announcement/announcement/', {
    id,
    status,
    updated_by
  });
  return response;
};
export { getannouncement, postannouncement, patchannouncement, patchannouncementForStop };
