import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
// import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// import SettingsLayout from '../layouts/settings';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable: any = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'new-password', element: <NewPassword /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <DashboardPage /> },
        { path: 'DisputeReport', element: <DashBoardDisputeDetailedPage /> },
        { path: 'payroll', element: <DashBoardPayrollandAttendance /> },
        { path: 'requests', element: <Requests /> },
        { path: 'employees', element: <Employeespage /> },
        { path: 'employees/addemployee', element: <AddEmployeepage /> },
        { path: 'employees/addemployeeinbulk', element: <AddEmployeeInBulkpage /> },
        { path: 'myprofile', element: <MyProfile /> },
        // { path: 'RunAnnouncement', element: <RunAnnouncement /> },
        { path: 'notification', element: <NotificationPage /> },
        { path: 'payroll/logaux', element: <LoganAux /> },
        // { path: 'payroll/PayrollCycle', element: <PayrollCyclePage /> },
        { path: 'payroll/mytimesheet', element: <MyTimeSheetPage /> },
        { path: 'payroll/MirrorMyTimeSheet', element: <MirrorMyTimeSheetPage /> },
        { path: 'payroll/disputerequest', element: <RaiseDisputerequest /> },
        { path: 'payroll/lockunlockPayroll', element: <LockUnlockPayrollPage /> },
        { path: 'payroll/ImportTimeSheet', element: <ImportTimeSheetPage /> },
        { path: 'payroll/ViewTimeSheet', element: <ViewTimeSheetPage /> },
        { path: 'payroll/PublishPayroll', element: <PublishPayrollPage /> },
        {
          path: 'payroll/BulkImportPayrollDataForMultiLob',
          element: <BulkImportPayrollDataForMultiLobPage />
        },
        {
          path: 'payroll/DeletePublishedDuplicates',
          element: <DeletePublishedDuplicatesPage />
        },
        {
          path: 'payroll/PayrollCycleCorrection',
          element: <PayrollCycleCorrectionPage />
        },
        { path: 'payroll/Myattendance', element: <MyAttendance /> },
        { path: 'payroll/ForceClockoutPage', element: <ForceClockoutPage /> },
        { path: 'payroll/InvalideAttendancePage', element: <InvalideAttendancePage /> },
        { path: 'payroll/LiveClockInDashBoard', element: <LiveClockInDashBoardPage /> },
        { path: 'revenue', element: <DashBoardRevenue /> },
        { path: 'billing', element: <BillingDashboardPage /> },
        { path: 'revenue/CurrencyAndConversionRates', element: <CurrencyAndConversionRatesPage /> },
        { path: 'reports', element: <DashBoardReports /> },
        {
          path: 'reports/billabletoPayableReportLobWise',
          element: <BillabletoPayableReportLobWisePage />
        },
        {
          path: 'reports/LobWisePayableAuxBreakUpReport',
          element: <LobWisePayableAuxBreakUpReportPage />
        },
        {
          path: 'reports/LobWiseNonPayableAuxBreakUpReport',
          element: <LobWiseNonPayableAuxBreakUpReportPage />
        },
        {
          path: 'reports/LobWiseBillableAuxBreakUpReport',
          element: <LobWiseBillableAuxBreakUpReportPage />
        },
        {
          path: 'reports/LobWiseNonBillableAuxBreakUpReport',
          element: <LobWiseNonBillableAuxBreakUpReportPage />
        },
        // {
        //   path: 'reports/CustomReport',
        //   element: <CustomReportPage />
        // },
        {
          path: 'reports/LobWiseTimesheetBreakupReport',
          element: <LobWiseTimesheetBreakupReportPage />
        },
        { path: 'reports/RequestHistoryReport', element: <RequestHistoryReportPage /> },
        { path: 'reports/LoggedAuxHistoryReport', element: <LoggedAuxHistoryReportPage /> },
        { path: 'reports/PublishPayrollLogsReport', element: <PublishPayrollLogsReportPage /> },
        { path: 'reports/MyTimeSheetReport', element: <MyTimeSheetReportPage /> },
        { path: 'reports/TimeSheetReport', element: <TimeSheetReportPage /> },
        { path: 'reports/PastClockinHistoryReport', element: <ClokinHistoryReportPage /> },
        { path: 'revenue/RevenueBudgeting', element: <RevenueBudgetingPage /> },
        { path: 'revenue/BudgetedRevenueTable', element: <BudgetedRevenueTablePage /> },
        { path: 'revenue/ActualVbudgetedTable', element: <ActualVbudgetedTablePage /> },
        {
          path: 'revenue/ActualRevenueTable',
          element: <ActualRevenueTablePage />
        },
        { path: 'reports/PayrollHoursReport', element: <PayrollHoursReportPage /> },
        { path: 'reports/Payablebreakupreport', element: <PayablebreakupreportPage /> },
        { path: 'reports/TransferHistoryReport', element: <TransferHistoryReportPage /> },
        { path: 'reports/UpComingTransferReport', element: <UpComingTransferReportPage /> },
        {
          path: 'settings',
          // element: <SettingsLayout />,
          children: [
            { path: '/', element: <Navigate to="/dashboard/settings/companydetails" replace /> },
            { path: 'companydetails', element: <CompanyDetailsPage /> },
            { path: 'clients', element: <ClientPage /> },
            { path: 'siteslist', element: <SitesPage /> },
            { path: 'division', element: <DivisionPage /> },
            { path: 'departments', element: <DepartementPage /> },
            { path: 'lineofbusiness', element: <LineOfBusinessPage /> },
            { path: 'GLCode', element: <GlCodesPage /> },
            { path: 'auxes', element: <AuxesPage /> },
            { path: 'accessProfile', element: <AccessProfilePage /> },
            { path: 'userRoles', element: <UserRolePage /> },
            { path: 'userlevel', element: <UserLevel /> },
            { path: 'users', element: <UserPage /> },
            { path: 'users/TransferAgents', element: <UserTransfer /> },
            { path: 'users/addemployeeinbulk', element: <AddEmployeeInBulkpage /> },
            { path: 'columnmapping', element: <ColumnMappingPage /> },
            { path: 'lobconfig', element: <LobConfigPage /> },
            { path: 'teamconfig', element: <TeamPage /> },
            { path: 'team/addBulk', element: <BulkTeamPage /> },
            { path: 'payrollcycle', element: <PayrollCyclePage /> },
            { path: 'payrollcycle/BulkImportPayrollCycles', element: <BulkImportPayrollCycles /> },
            { path: 'holidayslist', element: <HolidaysPage /> },
            { path: 'revenuecycle', element: <RevenueCyclePage /> },
            { path: 'runannouncement', element: <RunAnnouncement /> }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      // element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [{ path: '/', element: <Navigate to="/dashboard" replace /> }]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Setting Pages
const AuxesPage = Loadable(lazy(() => import('../pages/settings/auxesPage')));
const UserTransfer = Loadable(lazy(() => import('components/UsersTransfer')));
const UserLevel = Loadable(lazy(() => import('components/UserLevel')));
// const UserRoles = Loadable(lazy(() => import('components/UserRoles')));
// const AccessProfile = Loadable(lazy(() => import('components/AccessProfile')));
// const ColumnMapping = Loadable(lazy(() => import('components/ColumnMapping')));
// const LobConfig = Loadable(lazy(() => import('components/LobConfig')));
// const HolidayList = Loadable(lazy(() => import('components/HolidayList')));

// Payroll and Attendance

const MyTimeSheetPage = Loadable(
  lazy(() => import('../pages/payrollandattendance/MyTimeSheetPage'))
);
const MirrorMyTimeSheetPage = Loadable(
  lazy(() => import('../pages/payrollandattendance/MirrorMyTimeSheetPage'))
);
const RaiseDisputerequest = Loadable(
  lazy(() => import('../pages/payrollandattendance/RaiseDisputerequest'))
);
const Requests = Loadable(lazy(() => import('../pages/myrequests/Requests')));
// logAnAux
const LoganAux = Loadable(lazy(() => import('../pages/payrollandattendance/LoganAux')));

const PayrollCyclePage = Loadable(
  lazy(() => import('../pages/payrollandattendance/PayrollCyclePage'))
);
const BulkImportPayrollCycles = Loadable(
  lazy(() => import('../components/payrol-and-attanedance/payrollCycles/BulkImportPayrollCycles'))
);
const LockUnlockPayrollPage = Loadable(
  lazy(() => import('../pages/payrollandattendance/LockUnlockPayrollPage'))
);
const ImportTimeSheetPage = Loadable(
  lazy(() => import('../pages/payrollandattendance/ImportTimesheetPage'))
);

const ViewTimeSheetPage = Loadable(
  lazy(() => import('../pages/payrollandattendance/ViewTimeSheetPage'))
);
const PublishPayrollPage = Loadable(
  lazy(() => import('../pages/payrollandattendance/PublishPayrollPage'))
);
const BulkImportPayrollDataForMultiLobPage = Loadable(
  lazy(() => import('../pages/payrollandattendance/BulkImportPayrollDataForMultiLobPage'))
);
const DeletePublishedDuplicatesPage = Loadable(
  lazy(() => import('../pages/payrollandattendance/DeletePublishedDuplicatesPage'))
);
const PayrollCycleCorrectionPage = Loadable(
  lazy(() => import('../pages/payrollandattendance/PayrollCycleCorrectionPage'))
);

const DashBoardPayrollandAttendance = Loadable(
  lazy(() => import('../pages/payrollandattendance/DashBoardPayrollandAttendance'))
);
const NewPassword = Loadable(lazy(() => import('../pages/authentication/NewPassword')));
const Employeespage = Loadable(lazy(() => import('../pages/employees/Employeespage')));
const AddEmployeepage = Loadable(lazy(() => import('../pages/employees/AddEmployeepage')));
const AddEmployeeInBulkpage = Loadable(
  lazy(() => import('../pages/employees/AddEmployeeInBulkpage'))
);
const MyProfile = Loadable(lazy(() => import('../pages/myProfile/MyProfilePage')));
const RunAnnouncement = Loadable(
  lazy(() => import('../pages/runAnnouncement/RunAnnouncementPage'))
);
const NotificationPage = Loadable(lazy(() => import('../pages/notifications/NotificationPage')));
const MyAttendance = Loadable(lazy(() => import('../pages/payrollandattendance/MyAttendance')));

// Revenue
const DashBoardRevenue = Loadable(lazy(() => import('../pages/revenue/DashBoardRevenue')));
const CurrencyAndConversionRatesPage = Loadable(
  lazy(() => import('../pages/revenue/CurrencyAndConversionRatesPage'))
);

// Reports
const DashBoardReports = Loadable(lazy(() => import('../pages/payrollReports/DashBoardReports')));
const BillabletoPayableReportLobWisePage = Loadable(
  lazy(() => import('../pages/payrollReports/BillabletoPayableReportLobWisePage'))
);
const LobWisePayableAuxBreakUpReportPage = Loadable(
  lazy(() => import('../pages/payrollReports/LobWisePayableAuxBreakUpReportPage'))
);
const LobWiseNonPayableAuxBreakUpReportPage = Loadable(
  lazy(() => import('../pages/payrollReports/LobWiseNonPayableAuxBreakUpReportPage'))
);
const LobWiseBillableAuxBreakUpReportPage = Loadable(
  lazy(() => import('../pages/payrollReports/LobWiseBillableAuxBreakUpReportPage'))
);
const LobWiseNonBillableAuxBreakUpReportPage = Loadable(
  lazy(() => import('../pages/payrollReports/LobWiseNonBillableAuxBreakUpReportPage'))
);
// const CustomReportPage = Loadable(lazy(() => import('../pages/payrollReports/CustomReportPage')));
const LobWiseTimesheetBreakupReportPage = Loadable(
  lazy(() => import('../pages/payrollReports/LobWiseTimesheetBreakupReportPage'))
);
const RequestHistoryReportPage = Loadable(
  lazy(() => import('../pages/payrollReports/RequestHistoryReportPage'))
);
const LoggedAuxHistoryReportPage = Loadable(
  lazy(() => import('../pages/payrollReports/LoggedAuxHistoryReportPage'))
);
const PublishPayrollLogsReportPage = Loadable(
  lazy(() => import('../pages/payrollReports/PublishPayrollLogsReportPage'))
);
const MyTimeSheetReportPage = Loadable(
  lazy(() => import('../pages/payrollReports/MyTimeSheetReportPage'))
);
const TimeSheetReportPage = Loadable(
  lazy(() => import('../pages/payrollReports/TimeSheetReportPage'))
);
const PayrollHoursReportPage = Loadable(
  lazy(() => import('../pages/payrollReports/PayrollHoursReportPage'))
);
const PayablebreakupreportPage = Loadable(
  lazy(() => import('../pages/payrollReports/PayablebreakupreportPage'))
);
// const DashBoardRevenue = Loadable(lazy(() => import('../pages/revenue/DashBoardRevenue')));
const RevenueCyclePage = Loadable(lazy(() => import('../pages/revenueCycle/RevenueCyclePage')));
const RevenueBudgetingPage = Loadable(lazy(() => import('../pages/revenue/RevenueBudgetingPage')));
const BudgetedRevenueTablePage = Loadable(
  lazy(() => import('../pages/revenue/budgetedRevenueTablePage'))
);
const ActualVbudgetedTablePage = Loadable(
  lazy(() => import('../pages/revenue/actualVbudgetedTablePage'))
);
const ActualRevenueTablePage = Loadable(
  lazy(() => import('../pages/revenue/actualRevenueTablePage'))
);
const LiveClockInDashBoardPage = Loadable(
  lazy(() => import('../pages/payrollandattendance/LiveClockinDashboardPage'))
);
const ClokinHistoryReportPage = Loadable(
  lazy(() => import('../pages/payrollReports/clokinHistoryReportPage'))
);
const TransferHistoryReportPage = Loadable(
  lazy(() => import('../pages/payrollReports/TransferHistoryReportPage'))
);
const UpComingTransferReportPage = Loadable(
  lazy(() => import('../pages/payrollReports/UpComingTransferReportPage'))
);
const ForceClockoutPage = Loadable(
  lazy(() => import('../pages/payrollandattendance/ForceClockoutPage'))
);
const DashboardPage = Loadable(lazy(() => import('../pages/dashboard/DashBoardPage')));
const DashBoardDisputeDetailedPage = Loadable(
  lazy(() => import('../pages/dashboard/DashBoardDisputeDetailedPage'))
);
const BillingDashboardPage = Loadable(lazy(() => import('../pages/billing/BillingDashboardPage')));
const InvalideAttendancePage = Loadable(
  lazy(() => import('../pages/payrollandattendance/InvalideAttendancePage'))
);
const TeamPage = Loadable(lazy(() => import('../pages/team/teamPage')));
const BulkTeamPage = Loadable(lazy(() => import('../pages/team/addTeamBulkPage')));

// CompanyMaster

const CompanyDetailsPage = Loadable(
  lazy(() => import('../pages/companyMaster/CompanyDetailsPage'))
);
const ClientPage = Loadable(lazy(() => import('../pages/companyMaster/ClientPage')));
const SitesPage = Loadable(lazy(() => import('../pages/companyMaster/SitesPage')));
const DivisionPage = Loadable(lazy(() => import('../pages/settings/DivisionPage')));
const DepartementPage = Loadable(lazy(() => import('../pages/settings/DepartementPage')));
const LineOfBusinessPage = Loadable(lazy(() => import('../pages/settings/LineOfBusinessPage')));
const GlCodesPage = Loadable(lazy(() => import('../pages/settings/GlCodesPage')));
const ColumnMappingPage = Loadable(lazy(() => import('../pages/settings/ColumnMappingPage')));
const HolidaysPage = Loadable(lazy(() => import('../pages/settings/HolidaysPage')));
const UserPage = Loadable(lazy(() => import('../pages/settings/userPage')));
const AccessProfilePage = Loadable(lazy(() => import('../pages/settings/AccessProfilePage')));
const UserRolePage = Loadable(lazy(() => import('../pages/settings/userRolePage')));
const LobConfigPage = Loadable(lazy(() => import('../pages/settings/LobConfigPage')));
