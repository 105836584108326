import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled, useTheme } from '@material-ui/core/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import sidebarConfig from './SidebarConfig';
import useCheckPermissions from '../../components/common/checkPermission';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 50;
const APP_BAR_DESKTOP = 50;

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.default
}));

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 32,
  paddingBottom: theme.spacing(4),
  // backgroundColor: '#F9FAFB',
  background: theme.palette.mode === 'light' ? '#F9FAFB' : 'none',
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 32,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const [navBarmenus, setNavBarmenus] = useState<any[]>([]);
  const edit_company = useCheckPermissions({ value: 'edit_company' });
  const view_company = useCheckPermissions({ value: 'view_company' });

  const view_client = useCheckPermissions({ value: 'view_client' });
  const edit_client = useCheckPermissions({ value: 'edit_client' });

  const view_sites = useCheckPermissions({ value: 'view_sites' });
  const edit_sites = useCheckPermissions({ value: 'edit_sites' });

  const view_divisions = useCheckPermissions({ value: 'view_divisions' });
  const edit_divisions = useCheckPermissions({ value: 'edit_divisions' });

  const view_departments = useCheckPermissions({ value: 'view_departments' });
  const edit_departments = useCheckPermissions({ value: 'edit_departments' });

  const edit_lobs = useCheckPermissions({ value: 'edit_lobs' });
  const view_lobs = useCheckPermissions({ value: 'view_lobs' });

  const view_payroll_cycles = useCheckPermissions({ value: 'view_payroll_cycles' });
  const edit_payroll_cycle = useCheckPermissions({ value: 'edit_payroll_cycle' });

  const view_revenue_cycles = useCheckPermissions({ value: 'view_revenue_cycles' });
  const edit_revenue_cycle = useCheckPermissions({ value: 'edit_revenue_cycle' });

  const view_holiday_cycles = useCheckPermissions({ value: 'view_holiday_cycles' });
  const edit_holiday_cycle = useCheckPermissions({ value: 'edit_holiday_cycle' });

  const view_aux = useCheckPermissions({ value: 'view_aux' });
  const edit_aux = useCheckPermissions({ value: 'edit_aux' });
  const view_access_profile = useCheckPermissions({
    value: 'view_access_profile'
  });
  const edit_access_profile = useCheckPermissions({
    value: 'edit_access_profile'
  });

  const view_user_role = useCheckPermissions({ value: 'view_user_role' });
  const edit_user_role = useCheckPermissions({ value: 'edit_user_role' });

  const user_management_add_users = useCheckPermissions({ value: 'user_management_add_users' });
  const view_users = useCheckPermissions({ value: 'view_users' });
  const user_management_add_users_in_bulk = useCheckPermissions({
    value: 'user_management_add_users_in_bulk'
  });

  const view_column_mapping = useCheckPermissions({ value: 'view_column_mapping' });
  const edit_column_mapping = useCheckPermissions({ value: 'edit_column_mapping' });

  const view_lob_config = useCheckPermissions({ value: 'view_lob_config' });
  const edit_lob_config = useCheckPermissions({ value: 'edit_lob_config' });

  const view_gl_code = useCheckPermissions({ value: 'view_gl_code' });
  const edit_gl_code = useCheckPermissions({ value: 'edit_gl_code' });
  const run_announcements = useCheckPermissions({ value: 'run_announcements' });
  const view_requests_module = useCheckPermissions({ value: 'view_requests_module' });

  const revenue_budgeting = useCheckPermissions({ value: 'revenue_budgeting' });
  const budgeted_revenue_table = useCheckPermissions({ value: 'budgeted_revenue_table' });
  const actuals_revenue_table = useCheckPermissions({ value: 'actuals_revenue_table' });
  const budgeted_vs_actual_hours_table = useCheckPermissions({
    value: 'budgeted_vs_actual_hours_table'
  });
  const budgeted_vs_actual_revenue_table = useCheckPermissions({
    value: 'budgeted_vs_actual_revenue_table'
  });
  const currency_and_conversion_rates = useCheckPermissions({
    value: 'currency_and_conversion_rates'
  });
  const billing = useCheckPermissions({
    value: 'billing'
  });
  const view_teams = useCheckPermissions({ value: 'view_team' });
  const edit_teams = useCheckPermissions({ value: 'edit_team' });
  // console.log(sidebarConfig, 'sidebarConfigsidebarConfigsidebarConfig');
  useEffect(() => {
    sidebarConfig.map((item: any) => {
      item.items.map((item3: any, key3: any) => {
        if (item3.children) {
          item3.children.map((item2: any, key2: any) => {
            if (item2.title === 'Company Details' && !view_company && !edit_company) {
              delete item.items[key3].children[key2];
            } else if (item2.title === 'Clients' && !view_client && !edit_client) {
              delete item.items[key3].children[key2];
            } else if (item2.title === 'Sites' && !view_sites && !edit_sites) {
              delete item.items[key3].children[key2];
            } else if (item2.title === 'Division' && !view_divisions && !edit_divisions) {
              delete item.items[key3].children[key2];
            } else if (item2.title === 'Departments' && !view_departments && !edit_departments) {
              delete item.items[key3].children[key2];
            } else if (item2.title === 'Line Of Business' && !view_lobs && !edit_lobs) {
              delete item.items[key3].children[key2];
            } else if (item2.title === 'GL Codes' && !view_gl_code && !edit_gl_code) {
              delete item.items[key3].children[key2];
            } else if (item2.title === 'Aux' && !view_aux && !edit_aux) {
              delete item.items[key3].children[key2];
            } else if (
              item2.title === 'Access Profile' &&
              !view_access_profile &&
              !edit_access_profile
            ) {
              delete item.items[key3].children[key2];
            } else if (item2.title === 'User Role' && !view_user_role && !edit_user_role) {
              delete item.items[key3].children[key2];
            } else if (
              item2.title === 'Users' &&
              !user_management_add_users &&
              !view_users &&
              !user_management_add_users_in_bulk
            ) {
              delete item.items[key3].children[key2];
            } else if (
              item2.title === 'Column Mapping' &&
              !view_column_mapping &&
              !edit_column_mapping
            ) {
              delete item.items[key3].children[key2];
            } else if (item2.title === 'LOB Config' && !view_lob_config && !edit_lob_config) {
              delete item.items[key3].children[key2];
            } else if (item2.title === 'Teams' && !view_teams && !edit_teams) {
              delete item.items[key3].children[key2];
            } else if (item2.title === 'Run Announcement' && !run_announcements) {
              delete item.items[key3].children[key2];
            } else if (
              item2.title === 'Payroll Cycles' &&
              !view_payroll_cycles &&
              !edit_payroll_cycle
            ) {
              delete item.items[key3].children[key2];
            } else if (
              item2.title === 'Revenue Cycles' &&
              !view_revenue_cycles &&
              !edit_revenue_cycle
            ) {
              delete item.items[key3].children[key2];
            } else if (
              item2.title === 'Holidays List' &&
              !view_holiday_cycles &&
              !edit_holiday_cycle
            ) {
              delete item.items[key3].children[key2];
            }
          });
        } else if (item3.title === 'Requests' && !view_requests_module) {
          delete item.items[key3];
        } else if (
          item3.title === 'Revenue' &&
          !revenue_budgeting &&
          !budgeted_revenue_table &&
          !actuals_revenue_table &&
          !budgeted_vs_actual_hours_table &&
          !budgeted_vs_actual_revenue_table &&
          !currency_and_conversion_rates
        ) {
          delete item.items[key3];
        } else if (item3.title === 'Billing' && !billing) {
          delete item.items[key3];
        }
      });
    });
    sidebarConfig.map((item: any) => {
      const filterd = item.items.filter(Boolean);
      filterd.map((item3: any, key3: any) => {
        if (item3.children !== undefined) {
          const filter1 = item3.children.filter(Boolean);
          filterd[key3].children = filter1;
        }
        return true;
      });
      item.items = filterd;
      return true;
    });
    sidebarConfig.map((item: any, key: any) => {
      item.items.map((item3: any, key3: any) => {
        if (item3.children !== undefined) {
          if (item3.children.length <= 0) {
            delete sidebarConfig[key].items[key3];
          }
        }
        return true;
      });
      if (item.items.length <= 0) {
        delete sidebarConfig[key];
      }
      return true;
    });
    sidebarConfig.map((item: any, key: any) => {
      const filterd = item.items.filter(Boolean);
      if (filterd.length <= 0) {
        delete sidebarConfig[key];
      }
      return true;
    });
    const lastArr = sidebarConfig.filter(Boolean);
    // console.log(lastArr, 'lastArrlastArr');
    setNavBarmenus(lastArr);
  }, [
    view_requests_module,
    view_company,
    edit_company,
    view_client,
    edit_client,
    view_sites,
    edit_sites,
    view_divisions,
    edit_divisions,
    view_departments,
    edit_departments,
    view_lobs,
    edit_lobs,
    view_aux,
    edit_aux,
    view_access_profile,
    edit_access_profile,
    view_user_role,
    edit_user_role,
    user_management_add_users,
    view_users,
    user_management_add_users_in_bulk,
    view_column_mapping,
    edit_column_mapping,
    view_lob_config,
    edit_lob_config,
    view_payroll_cycles,
    edit_payroll_cycle,
    view_revenue_cycles,
    edit_revenue_cycle,
    view_holiday_cycles,
    edit_holiday_cycle,
    run_announcements,
    view_gl_code,
    edit_gl_code
  ]);

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
        navBar={navBarmenus}
      />
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          ...(collapseClick && {
            ml: '54px'
          })
        }}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
