import { useEffect, ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const whitelist = ['/', '/admin', '/results', '/profile'];
const trackOnly = ['/dashboard/payroll/Myattendance'];
type pageRefreshidealProps = {
  children?: ReactNode;
};

const PageRefreshIdeal = ({ children }: pageRefreshidealProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  let timeout: NodeJS.Timeout | null = null;

  const goBackToHome = () => {
    navigate(0);

    // navigate('/dashboard/payroll');
    // code to reset the application
  };

  const restartAutoReset = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      goBackToHome();
    }, 1000 * 60 * 3);
  };

  const onMouseMove = () => {
    restartAutoReset();
  };

  useEffect(() => {
    // Whitelist certain pages
    // trackOnly these pages tracks
    let preventResett = false;
    for (const path of trackOnly) {
      if (path !== pathname) {
        preventResett = true;
      }
    }
    if (preventResett) {
      return;
    }

    // initiate timeout
    restartAutoReset();

    // listen for mouse events
    window.addEventListener('mousemove', onMouseMove);

    // cleanup
    // eslint-disable-next-line consistent-return
    return () => {
      if (timeout) {
        clearTimeout(timeout);
        window.removeEventListener('mousemove', onMouseMove);
      }
    };
  }, [pathname]);
  return null;
};

export default PageRefreshIdeal;
