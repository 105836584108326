import axios from 'axios';
import { baseUrl } from '../constants';
// ----------------------------------------------------------------------

const axiosInstance = axios.create();

// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Cookies';
axios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);
axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    config.baseURL = baseUrl;
    const beareToken = `bearer ${localStorage.getItem('accessToken')}`;
    if (localStorage.getItem('accessToken') !== null) {
      config.headers.Authorization = beareToken;
    }
    return config;
  },
  (error) =>
    // Do something with request error
    Promise.reject(error)
);

export default axiosInstance;
