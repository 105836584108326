import { useEffect, useState } from 'react';
import useAuth from 'hooks/useAuth';

type PermissionProps = {
  value: any;
};
export default function useCheckPermissions(props: PermissionProps) {
  const { value } = props;
  const [issuperUser, setIssuperUser] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const [havePermission, setHavePermission] = useState(true);
  const { user } = useAuth();
  useEffect(() => {
    setIssuperUser(user?.is_superuser);
    setUserRole(user?.user_role);
  }, []);

  useEffect(() => {
    if (!issuperUser && userRole !== null) {
      setHavePermission(user?.user_role?.accessprofile[value]);
    }
  }, [userRole, value]);

  return havePermission;
}
